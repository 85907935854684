























































































































































import {
  defineComponent,
  computed,
  ref,
  watchEffect,
  watch,
  toRefs,
  reactive
} from '@vue/composition-api';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import { useUserState, useDbGetters, useUserActions } from '@/store';
import {
  GRADE_LEVEL,
  SUPER_GENDER,
  ETHNICITY,
  GUARDIAN,
  HOME_LANG,
  STATE
} from '@/constants/portfolio';

export default defineComponent({
  name: 'AgreementGuardian',

  components: {
    ATeleInput,
    AAutocomplete
  },

  props: {
    type: {
      type: Boolean,
      default: false
    },
    studentDoc: {
      type: Object,
      default: () => {}
    }
  },

  setup(props: any, ctx: any) {
    const { user }: any = useUserState(['user']);
    const { collection } = useDbGetters(['collection']);
    const { getDocumentByIdAndType } = useUserActions(['getDocumentByIdAndType']);
    const agreeGuardianTerms = ref(false);
    const isGuardianPhoneValid = ref(false);
    const guardianFormattedNumber = ref('');
    const guardianValidation: any = ref('');
    const isGuardianInvalid = ref(false);
    const student = ref();
    const confirm_social_security_number = ref('');
    const guardianData: any = ref({
      email: '',
      first_name: '',
      last_name: '',
      guardian_phone: '',
      relationship: '',
      birth_date: '',
      social_security_number: '',
      signature: '',
      streetAddress: ''
    });

    const isPaid = computed(() => {
      let compensation;
      if (props.type) {
        compensation = props.studentDoc?.offerDetails?.compensation?.substring(0, 6);
      } else {
        compensation = props.studentDoc?.data?.offerDetails?.compensation?.substring(0, 6);
      }
      if (compensation === 'Unpaid') return false;
      return true;
    });

    const isSSNIdentical = computed(() => {
      if (guardianData.value.social_security_number === confirm_social_security_number.value)
        return true;
      return false;
    });

    async function loadInitialData() {
      let studentGuarduian;
      if (ctx.root.$route.params.studentId) {
        student.value = await getDocumentByIdAndType({
          document_type: 'Student',
          document_id: ctx.root.$route.params.studentId,
          public_id: student?.value?._id
        });

        studentGuarduian = await getDocumentByIdAndType({
          document_type: 'StudentPortfolio',
          document_id: student.value.participant_id,
          public_id: student?.value?._id
        });
      } else {
        studentGuarduian = await getDocumentByIdAndType({
          document_type: 'StudentPortfolio',
          document_id: user.value?._id,
          public_id: student?.value?._id
        });
      }

      guardianData.value = {
        email: studentGuarduian?.guardian?.email,
        first_name: studentGuarduian?.guardian?.firstName,
        last_name: studentGuarduian?.guardian?.lastName,
        guardian_phone: studentGuarduian?.guardian?.phone?.substring(2),
        relationship: studentGuarduian?.guardian?.relationship,
        streetAddress: studentGuarduian?.home?.streetAddress
      };
    }

    function getGuardianValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        guardianFormattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        guardianFormattedNumber.value = '';
      }
      isGuardianPhoneValid.value = val.valid;
      ctx.emit('getData', guardianData.value);
    }

    const guardianPhoneValid = computed(() => {
      if (isGuardianPhoneValid.value && guardianData.value.guardian_phone) {
        return true;
      }
      return false;
    });

    const isGuardianPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (
        guardianData.value?.guardian_phone?.includes(substring) ||
        guardianData.value?.guardian_phone?.includes(substring1)
      ) {
        return false;
      }

      return true;
    });

    watchEffect(() => {
      if (guardianValidation.value) {
        isGuardianInvalid.value = guardianValidation.value?.flags?.invalid;
        ctx.emit('validateGuardian', isGuardianInvalid.value);
      }
      if (isGuardianPhoneValid.value && isGuardianPhoneCheck.value) {
        ctx.emit('validatePhone', true);
      } else {
        ctx.emit('validatePhone', false);
      }
      if (!isPaid.value) {
        ctx.emit('validssn', true);
      }
    });

    watch(agreeGuardianTerms, val => {
      ctx.emit('agreeTerms', val);
    });

    watch(collection, val => {
      if (val) {
        loadInitialData();
      }
    });

    if (user.value) {
      loadInitialData();
    }

    if (
      ctx.root.$route.params.programId ||
      props.studentDoc?.program_id ||
      ctx.root.$route.params.studentId
    ) {
      loadInitialData();
    }

    const formOpt = reactive({
      gradeLevel: GRADE_LEVEL,
      superGender: SUPER_GENDER,
      ethnicityCulture: ETHNICITY,
      guardianRelationship: GUARDIAN,
      homeLanguageOpts: HOME_LANG,
      stateOpts: STATE
    });

    function setStreetValue(e) {
      if (e.target) {
        guardianData.value.streetAddress = e.target.value;
        ctx.emit('getData', guardianData.value);
      } else if (e.name) {
        guardianData.value.streetAddress = e.formatted_address;
        ctx.emit('getData', guardianData.value);
      }
    }

    return {
      setStreetValue,
      ...toRefs(formOpt),
      isGuardianPhoneCheck,
      isGuardianPhoneValid,
      guardianFormattedNumber,
      getGuardianValidation,
      guardianPhoneValid,
      guardianData,
      loadInitialData,
      agreeGuardianTerms,
      guardianValidation,
      isGuardianInvalid,
      student,
      isSSNIdentical,
      confirm_social_security_number,
      isPaid
    };
  }
});
