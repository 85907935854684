var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"50%"}},[_c('validation-observer',{ref:"guardianValidation"},[_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('h3',[_vm._v("Enter Your Guardian Information")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"error-messages":errors,"label":"Guardian First Name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.first_name),callback:function ($$v) {_vm.$set(_vm.guardianData, "first_name", $$v)},expression:"guardianData.first_name"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"error-messages":errors,"label":"Guardian Last Name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.last_name),callback:function ($$v) {_vm.$set(_vm.guardianData, "last_name", $$v)},expression:"guardianData.last_name"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Guardian Email","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.email),callback:function ($$v) {_vm.$set(_vm.guardianData, "email", $$v)},expression:"guardianData.email"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticStyle:{"margin-bottom":"25px"},attrs:{"error-message":errors,"is-rounded":"","placeholder":"Search Home Address","label":"Guardian Address","type":"street"},on:{"val":_vm.setStreetValue},model:{value:(_vm.guardianData.streetAddress),callback:function ($$v) {_vm.$set(_vm.guardianData, "streetAddress", $$v)},expression:"guardianData.streetAddress"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.guardianRelationship,"error-messages":errors,"label":"Guardian Relationship to Student","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.relationship),callback:function ($$v) {_vm.$set(_vm.guardianData, "relationship", $$v)},expression:"guardianData.relationship"}})]}}])}),(!_vm.type)?_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-6",attrs:{"input-text":"black","label":"Guardian Mobile Phone Number","placeholder":"Guardian phone","is-rounded":true},on:{"get":_vm.getGuardianValidation},model:{value:(_vm.guardianData.guardian_phone),callback:function ($$v) {_vm.$set(_vm.guardianData, "guardian_phone", $$v)},expression:"guardianData.guardian_phone"}})],1):_vm._e(),(_vm.type && _vm.isPaid)?_c('validation-provider',{attrs:{"slim":"","rules":"required|min:11|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('h3',[_vm._v("Student W9 Tax Information")])]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-##-####'),expression:"'###-##-####'"}],staticClass:"mt-2",attrs:{"error-messages":errors,"label":"Enter Student Social Security Number","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.social_security_number),callback:function ($$v) {_vm.$set(_vm.guardianData, "social_security_number", $$v)},expression:"guardianData.social_security_number"}})]}}],null,false,876744280)}):_vm._e(),(_vm.type && _vm.isPaid)?_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-##-####'),expression:"'###-##-####'"}],attrs:{"error-messages":errors,"label":"Confirm Student Social Security Number","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('validssn', _vm.isSSNIdentical)}},model:{value:(_vm.confirm_social_security_number),callback:function ($$v) {_vm.confirm_social_security_number=$$v},expression:"confirm_social_security_number"}}),(_vm.confirm_social_security_number && !_vm.isSSNIdentical)?_c('p',{staticClass:"error-alert"},[_vm._v(" Student Social Security Number must be identical ")]):_vm._e()]}}],null,false,2889777122)}):_vm._e(),(_vm.type)?_c('v-checkbox',{staticClass:"signup__conditions",attrs:{"single-line":"","outlined":"","full-width":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to my minor's participation according to language above, as well as the "),_c('a',{attrs:{"href":"https://www.iubenda.com/terms-and-conditions/21126817"}},[_vm._v("Terms & Conditions")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.iubenda.com/privacy-policy/21126817"}},[_vm._v("Privacy Policy")]),_vm._v(" for signing up for a PilotCity.com account linked to my minor ")])]},proxy:true}],null,false,627818068),model:{value:(_vm.agreeGuardianTerms),callback:function ($$v) {_vm.agreeGuardianTerms=$$v},expression:"agreeGuardianTerms"}}):_c('v-checkbox',{staticClass:"signup__conditions",attrs:{"single-line":"","outlined":"","full-width":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("I acknowledge the above & confirm the information is true")])]},proxy:true}]),model:{value:(_vm.agreeGuardianTerms),callback:function ($$v) {_vm.agreeGuardianTerms=$$v},expression:"agreeGuardianTerms"}}),(_vm.type)?_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":!_vm.agreeGuardianTerms,"error-messages":errors,"label":"Sign electronically by typing your full name","outlined":"","rounded":""},on:{"input":function($event){return _vm.$emit('getData', _vm.guardianData)}},model:{value:(_vm.guardianData.signature),callback:function ($$v) {_vm.$set(_vm.guardianData, "signature", $$v)},expression:"guardianData.signature"}})]}}],null,false,364458469)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }